import React from 'react'
import useWindowDimensions from '../../components/hooks/useWindowDimension'
import Navigator from '../../components/navigator'
import avator from '../../images/IMG_5506.png'
import { GithubOutlined, LinkedinOutlined, FacebookOutlined, YoutubeOutlined, TwitterOutlined, WechatOutlined, InstagramOutlined } from '@ant-design/icons'
import { Image } from 'antd'

export default function HomePage(params) {
    const { height, width } = useWindowDimensions()

    return (
        <div style={{ display: 'flex', height: height, justifyContent: 'space-evenly', alignItems: 'center' }}>
            {/* image - left */}
            <div>
                <Image src={avator} height={height/2} width={height/2/0.75} preview={false} />
            </div>
            {/* right */}
            <div className='right' style={{ width: 700 }}>
                <div style={{ fontSize: '15px' }}>
                    <h1>Hi there! I'm Roger X Wu 👋</h1>
                    <div style={{ display: 'flex', paddingBottom: '20px' }}>
                        <a href='https://github.com/rogerxwu/' target="_blank" rel="noreferrer"><GithubOutlined style={{ fontSize: '30px', paddingRight: '10px' }} /></a>
                        <a href='https://www.linkedin.com/in/rogerxwu/' target="_blank" rel="noreferrer"><LinkedinOutlined style={{ fontSize: '30px', paddingRight: '10px' }} /></a>
                        <a href='https://www.facebook.com/rogerxwu/' target="_blank" rel="noreferrer"><FacebookOutlined style={{ fontSize: '30px', paddingRight: '10px' }} /></a>
                        <a href='https://www.youtube.com/channel/UCD9VdtWF4rB3aKTYXHEP6Cw' target="_blank" rel="noreferrer"><YoutubeOutlined style={{ fontSize: '30px', paddingRight: '10px' }} /></a>
                        <a href='https://twitter.com/rogerxwu' target="_blank" rel="noreferrer"><TwitterOutlined style={{ fontSize: '30px', paddingRight: '10px' }} /></a>
                        <a href='https://www.instagram.com/rogerxwu/' target="_blank" rel="noreferrer"><InstagramOutlined style={{ fontSize: '30px', paddingRight: '10px' }} /></a>
                        <a href='https://thirdciv-images.s3.us-west-2.amazonaws.com/wechat_qrcode.jpg' target="_blank" rel="noreferrer"><WechatOutlined style={{ fontSize: '30px', paddingRight: '10px' }} /></a>
                    </div>

                    <div>
                        <p>Currently working as a network automation engineer at Aurora Innoviation Inc. My master's degree in ECE graduated from SUNY Binghamton University.  </p>

                        <p>Enthusiastic on various technologies that has the potiential to change people's lives, such as cloud, 3D printing, cryptocurrency, AL/ML, new energy. Keep an eye on how technological innovations can improve efficiency while reshaping the natural environment.  </p>

                        <p>Passionate about entrepreneurship, I believe that engineering skill is the hands of man and business vision is the eyes of man. When the chemistry of skill and vision comes into play, you can make something that can change the world. </p>

                        <p>A lifelong learner, 10 years tennis player, certified scuba diver, camper, loves the outdoors, loves nature. </p>

                        <p>Fate and destiny coexist. Do your best to move forward, and the final glory goes to God. </p>
                    </div>
                </div>
            </div>

            {/* Menu */}
            <Navigator type_home='primary'/>

        </div>
    )
}