import './App.css';
import { useEffect } from 'react';
import axios from 'axios'
import { API, graphqlOperation } from 'aws-amplify'
import { createVisitingHistory } from './graphql/mutations'

//new
import { Routes, Route } from "react-router-dom";
import ProjectPage from './pages/project';
import VisitorPage from './pages/visitor';
import HomePage from './pages/home';
import ContactPage from './pages/contact';
import SkillPage from './pages/skill';
import CareerPage from './pages/career';

export default function App() {
  //creating function to load ip address from the API
  const recordVisiting = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    //console.log(res.data)
    try {
      await API.graphql(graphqlOperation(createVisitingHistory, {input: {
        ipv4: res.data.IPv4,
        city: res.data.city,
        country_code:res.data.country_code,
        lat:res.data.lat,
        long:res.data.long,
        postal:res.data.postal,
        state: res.data.state
      }}))

    } catch (e) {
      console.log(e)
    }
    /* IPv4: "70.235.140.230"
    city: "San Jose"
    country_code: "US"
    country_name: "United States"
    latitude: 37.296
    longitude: -121.8939
    postal: "95125"
    state: "California" */
  }

  useEffect(() => {
    recordVisiting()
  }, [])


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/skill" element={<SkillPage />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/visitor" element={<VisitorPage />} />
      </Routes>
    </div>
  );
}
