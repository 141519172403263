import React from 'react'
import { Timeline } from 'antd'
import useWindowDimensions from '../../components/hooks/useWindowDimension'
import Navigator from '../../components/navigator'

export default function CareerPage(params) {
    const {height} = useWindowDimensions()
    return (
        <div style={{ display: 'flex', height: height, justifyContent: 'space-evenly', alignItems: 'center' }}>
            <Timeline>
                <Timeline.Item>...</Timeline.Item>
                <Timeline.Item>Master graduated from SUNY binghamton, Oct 2015 - May 2017</Timeline.Item>
                <Timeline.Item>Network Engineer, Alibaba Group, Oct,2017 - Feb,2019</Timeline.Item>
                <Timeline.Item>Network Engineer - IT Manager, JD.com, Feb,2019 - Oct,2021</Timeline.Item>
                <Timeline.Item>Network Automation Engineer, Aurora Innoviation, Oct,2021 - Now</Timeline.Item>
                <Timeline.Item>...</Timeline.Item>
            </Timeline>




            <Navigator type_career='primary'/>
        </div>
    )
}