import React from 'react'
import useWindowDimensions from '../hooks/useWindowDimension'
import { Button } from 'antd'
import { useNavigate } from "react-router-dom"

export default function Navigator(props) {
    let navigate = useNavigate()

    const { width } = useWindowDimensions()
    return (
        <div style={{ zIndex: 100, position: 'fixed', top: 40, display: 'flex', justifyContent: 'space-evenly', width: width / 2 }}>
            {/* <div style={{ zIndex: 100, position: 'fixed', right: 20, display: 'grid', alignContent: 'space-evenly', height: height / 2 }}> */}
            <Button shape='round' size='large' type={props.type_home} onClick={() => { navigate("/") }}>Home</Button>
            <Button shape='round' size='large' type={props.type_career} onClick={() => { navigate("/career") }}>Career</Button>
            <Button shape='round' size='large' type={props.type_project} onClick={() => { navigate("/project") }}>Projects</Button>
            <Button shape='round' size='large' type={props.type_skill} onClick={() => { navigate("/skill") }}>Skills</Button>
            <Button shape='round' size='large' type={props.type_visitor} onClick={() => { navigate("/visitor") }}>Visitors</Button>
            <Button shape='round' size='large' type={props.type_contact} onClick={() => { navigate("/contact") }}>Contact</Button>
        </div>
    )
}