import { Image } from 'antd'
import React from 'react'
import useWindowDimensions from '../../components/hooks/useWindowDimension'
import Navigator from '../../components/navigator'

export default function ProjectPage(params) {
    const { height } = useWindowDimensions()


    return (
        <div style={{ display: 'flex', height: height, justifyContent: 'space-evenly', alignItems: 'center' }}>
            <div>
                <h3>Outdoor - WeChat miniprogram</h3>
                <h3>sportshub - web/mobile</h3>
                <h3>thirdciv.com - Web</h3>
                <h3>sylphai.com - Web</h3>
            </div>






            <Navigator type_project='primary' />
        </div>
    )
}