/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVisitingHistory = /* GraphQL */ `
  mutation CreateVisitingHistory(
    $input: CreateVisitingHistoryInput!
    $condition: ModelVisitingHistoryConditionInput
  ) {
    createVisitingHistory(input: $input, condition: $condition) {
      id
      ipv4
      country_code
      state
      postal
      lat
      long
      city
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVisitingHistory = /* GraphQL */ `
  mutation UpdateVisitingHistory(
    $input: UpdateVisitingHistoryInput!
    $condition: ModelVisitingHistoryConditionInput
  ) {
    updateVisitingHistory(input: $input, condition: $condition) {
      id
      ipv4
      country_code
      state
      postal
      lat
      long
      city
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVisitingHistory = /* GraphQL */ `
  mutation DeleteVisitingHistory(
    $input: DeleteVisitingHistoryInput!
    $condition: ModelVisitingHistoryConditionInput
  ) {
    deleteVisitingHistory(input: $input, condition: $condition) {
      id
      ipv4
      country_code
      state
      postal
      lat
      long
      city
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
