import React from 'react'
import useWindowDimensions from '../../components/hooks/useWindowDimension'
import Navigator from '../../components/navigator'

export default function ContactPage(params) {
    const {height} = useWindowDimensions()


    return (
        <div style={{ display: 'flex', height: height, justifyContent: 'space-evenly', alignItems: 'center' }}>
            <div style={{ fontSize: '20px' }}>
                <p>Email: </p>
                <p>xianwuusa@gmail.com</p>
                <p>rogerxianwu@gmail.com</p>
            </div>





            <Navigator type_contact='primary'/>
        </div>
    )
}