import { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { listVisitingHistories } from '../../graphql/queries'
import useWindowDimensions from '../../components/hooks/useWindowDimension'
import Navigator from '../../components/navigator'
import { Chart } from 'react-google-charts'
import { Card, Skeleton } from 'antd'
import axios from 'axios'
import moment from 'moment'

export default function VisitorPage(params) {
    const { height } = useWindowDimensions()
    const [loading, setLoading] = useState(true)
    const [totalVisiting, setTotalVisiting] = useState()
    const [countries, setCountries] = useState()
    const [traffic, setTraffic] = useState()

    useEffect(() => {
        fetchTraffic()
        //fetchCountry()

    }, [])

    async function fetchTraffic() {
        try {
            const res = await axios.get('https://tpdo9qvdp3.execute-api.us-west-1.amazonaws.com/visitings/v1/items')
            //console.log(res)
            setTotalVisiting(res.data.count)

            const data_country = [["Country", 'Amount']]
            for (const [key, value] of Object.entries(res.data.country)) {
                data_country.push([key, value])
            }

            setCountries(data_country)

            const data_traffic = [["Date", 'traffic']]
            Object.keys(res.data.traffic).sort(function (a, b) {
                return moment(a, 'MM/DD/YYYY').toDate() - moment(b, 'MM/DD/YYYY').toDate();
            }).forEach(function (key) {

                data_traffic.push([key, res.data.traffic[key]])
            })
            setTraffic(data_traffic)
        } catch (error) {

        } finally {
            setLoading(false)
        }

    }




    return (
        <div style={{ display: 'flex', height: height, justifyContent: 'center', alignItems: 'center' }}>
            <Navigator type_visitor='primary' />

            <div>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Card>
                            <h2>Countries:</h2>
                            {loading ? <Skeleton active style={{ width: 400, height: 200 }} /> :
                                <Chart
                                    chartEvents={[
                                        {
                                            eventName: "select",
                                            callback: ({ chartWrapper }) => {
                                                const chart = chartWrapper.getChart();
                                                const selection = chart.getSelection();
                                                if (selection.length === 0) return;
                                                const region = countries[selection[0].row + 1];
                                                console.log("Selected : " + region);
                                            },
                                        },
                                    ]}
                                    chartType="GeoChart"
                                    width={400}
                                    height={200}
                                    data={countries}

                                />}


                        </Card>
                        <Card>
                            <h2>Countries:</h2>
                            {loading ? <Skeleton active style={{ width: 400, height: 200 }} /> :
                                <Chart
                                    chartType="PieChart"
                                    data={countries}
                                    width={400}
                                    height={200}
                                />
                            }
                        </Card>
                        <Card style={{ width: 450, height: 300 }}>
                            <h2>Total traffic:</h2>
                            {loading ? <Skeleton active style={{ width: 450, height: 300 }} /> :
                                <div style={{ fontWeight: 'bold', fontSize: 120 }}>{totalVisiting}</div>
                            }
                        </Card>
                    </div>
                    <div>
                        <Card>
                            <h2>Last 14 days traffic trend:</h2>
                            {loading ? <Skeleton active style={{ width: 400, height: 200 }} /> :
                                <Chart
                                    chartType="Line"
                                    width={1300}
                                    height={300}
                                    data={traffic}
                                />
                            }
                        </Card>

                    </div>
                </div>
            </div>
        </div>
    )


}
