import React from 'react'
import { ForceGraph2D } from 'react-force-graph';
import useWindowDimensions from '../../components/hooks/useWindowDimension';
import Navigator from '../../components/navigator';

export default function SkillPage(params) {
    const { height } = useWindowDimensions()
    const myData = {
        "nodes": [
            {
                "id": "1",
                "name": "Skills",
                "val": 1,
                "color": "Purple"
            },
            {
                "id": "143",
                "name": "Programming Language",
                "val": 1,
            },
            {
                "id": "13",
                "name": "Language",
                "val": 1,
            },
            {
                "id": "14",
                "name": "Engineering",
                "val": 1,
            },
            {
                "id": "141",
                "name": "Software Enginnering",
                "val": 1,
            },
            {
                "id": "142",
                "name": "Cloud Engineering",
                "val": 1,
            },
            {
                "id": "144",
                "name": "Network Engineering",
                "val": 1,
            },
            {
                "id": "1431",
                "name": "Python",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "1432",
                "name": "Javascript",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "131",
                "name": "Chinese",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "132",
                "name": "English",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "1413",
                "name": "Ansible",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "1441",
                "name": "Network Inventory Management",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "1442",
                "name": "Network Automation",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "1443",
                "name": "Monitoring",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "1444",
                "name": "NetDevOps",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "1412",
                "name": "Full-Stack development",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "1411",
                "name": "React",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "15",
                "name": "UI/UX Design",
                "val": 10,
                "color": "blue"
            },
            {
                "id": "12",
                "name": "Product Mangement",
                "val": 10,
                "color": "blue"
            },
        ],
        "links": [
            {
                "source": "1",
                "target": "12"
            },
            {
                "source": "1",
                "target": "13"
            },
            {
                "source": "1",
                "target": "14"
            },
            {
                "source": "1",
                "target": "15"
            },
            {
                "source": "14",
                "target": "141"
            },
            {
                "source": "14",
                "target": "142"
            },
            {
                "source": "14",
                "target": "143"
            },
            {
                "source": "143",
                "target": "1432"
            },
            {
                "source": "143",
                "target": "1431"
            },
            {
                "source": "13",
                "target": "131"
            },
            {
                "source": "13",
                "target": "132"
            },
            {
                "source": "14",
                "target": "144"
            },
            {
                "source": "141",
                "target": "1411"
            },
            {
                "source": "141",
                "target": "1412"
            },
            {
                "source": "141",
                "target": "1413"
            },
            {
                "source": "144",
                "target": "1441"
            },
            {
                "source": "144",
                "target": "1442"
            },
            {
                "source": "144",
                "target": "1443"
            },
            {
                "source": "144",
                "target": "1444"
            },
        ]
    }

    return (
        <div style={{ display: 'flex', height: height, justifyContent: 'space-evenly', alignItems: 'center' }}>
            <Navigator type_skill='primary'/>






            <ForceGraph2D
                graphData={myData}
                width={700}
                height={700}
                backgroundColor={'white'}
                nodeAutoColorBy={'group'}
                nodeCanvasObject={(node, ctx, globalScale) => {
                    const label = node.name;
                    const fontSize = 20 / globalScale;
                    ctx.font = `${fontSize}px Sans-Serif`;
                    const textWidth = ctx.measureText(label).width;
                    const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

                    ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
                    ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillStyle = node.color;
                    ctx.fillText(label, node.x, node.y);

                    node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
                }}
                nodePointerAreaPaint={(node, color, ctx) => {
                    ctx.fillStyle = color;
                    const bckgDimensions = node.__bckgDimensions;
                    bckgDimensions && ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
                }}
            />

        </div>
    )
}